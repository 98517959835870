<template lang="">
  <div>
    <!-- {{ offerByID }} -->
    <b-row class="match-height">
      <b-col cols="12" xl="12" lg="12" md="12">
        <OffersAction :offer-entry="offerByID" />
      </b-col>
    </b-row>
    <b-row class="match-height">
      <b-col cols="12" xl="12" lg="12" md="12">
        <OffersViewInfoCardsVue :offer-entry="offerByID" />
        <OffersViewStoragesTable :offer-entry="offerByID" />
      </b-col>
    </b-row>
  </div>
</template>
<script>
import { BRow, BCol } from 'bootstrap-vue'
import OffersViewInfoCardsVue from './OffersViewInfoCards.vue'
import OffersViewStoragesTable from './OffersViewStoragesTable.vue'
import OffersAction from './OffersViewActions.vue'

export default {
  components: {
    BRow,
    BCol,
    OffersViewInfoCardsVue,
    OffersViewStoragesTable,
    OffersAction,
  },
  props: ['id'],
  computed: {
    offerByID() {
      return this.$store.getters.offerByID(Number(this.id))
    },
  },
}
</script>
<style lang=""></style>
