<template>
  <div>
    <!-- {{ offerEntry }} -->
    <b-card>
      <b-row>
        <b-col>
          <b-button-toolbar justify>
            <!--group 1 -->
            <b-button-group>
              <b-button
                block
                variant="success"
                :disabled="!offerEntry.isActive"
                @click="createOrder"
                ><feather-icon icon="CheckSquareIcon" class="mr-50" />
                <span class="align-middle"
                  >Buchung für {{ offerEntry.firstName }}
                  {{ offerEntry.lastName }} erstellen</span
                >
              </b-button>
            </b-button-group>

            <!-- group 2 -->
            <b-button-group>
              <b-button variant="danger">
                <feather-icon icon="TrashIcon" />
              </b-button>
            </b-button-group>
          </b-button-toolbar>
        </b-col>
      </b-row>
    </b-card>
    <b-modal
      id="modal-booking"
      size="lg"
      title="Buchen"
      ok-only
      scrollable
      ok-title="Buchung durchführen"
    >
      <b-card-text>
        <h5>Buchung</h5>
        // JSON to string
        <pre>{{ JSON.stringify(offerEntry, null, 2) }}</pre>
      </b-card-text>
    </b-modal>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BCardText,
  BButton,
  BFormCheckbox,
  BButtonToolbar,
  BButtonGroup,
  BModal,
  VBModal,
} from 'bootstrap-vue'
import helper from '@/helper/helper'
// import inquiriesHelper from '@/helper/inquiriesHelper'

export default {
  setup() {
    const { getFormattedDate } = helper()
    // const { isOfferable } = inquiriesHelper()

    return { getFormattedDate }
  },
  components: {
    BCard,
    BRow,
    BCol,
    BCardText,
    BButton,
    BFormCheckbox,
    BButtonToolbar,
    BButtonGroup,
    BModal,
    VBModal,
  },
  props: {
    offerEntry: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      isOfferable: true,
    }
  },
  methods: {
    createOrder() {
      console.log(
        `createOrder() - offerEntry.id: ${this.offerEntry.id}`,
        this.offerEntry
      )

      // this.$bvModal.show('modal-booking')
      const activeStorage = this.offerEntry.storages.filter(
        storage => storage.storageReservation === true
      )

      this.$router.push({
        name: 'Direktbuchung',
        params: {
          booking: this.offerEntry,
          storages: activeStorage,
          parentRoute: 'offer',
        },
      })
    },
  },
}
</script>
<style lang="scss">
hr.style1 {
  border-top: 1px solid #8c8b8b;
}
hr.style5 {
  background-color: #fff;
  border-top: 2px dashed #8c8b8b;
}
</style>
