<template>
  <div>
    <!-- {{ isDisabled() }} -->
    <b-row class="match-height">
      <!-- User Info: Left col -->
      <b-col
        cols="12"
        xl="8"
        lg="7"
        md="12"
      >
        <!-- Name & Email -->
        <b-card>
          <span>{{ offerEntry.salutation }}</span>
          <h3>{{ offerEntry.firstName }} {{ offerEntry.lastName }}</h3>
          <span class="card-text">{{ offerEntry.email }}</span>

          <hr class="style1">

          <!-- Right Col: Table -->

          <table class="mt-2 mt-xl-0 w-100">
            <tr>
              <th class="pb-50">
                <feather-icon
                  icon="UsersIcon"
                  class="mr-75"
                />
                <span class="font-weight-bold">Unternehmen</span>
              </th>
              <td class="pb-50">
                {{ offerEntry.company }}
              </td>
            </tr>

            <tr>
              <th class="pb-50">
                <feather-icon
                  icon="MapIcon"
                  class="mr-75"
                />
                <span class="font-weight-bold">Strasse</span>
              </th>
              <td class="pb-50 text-capitalize">
                {{ offerEntry.street }} {{ offerEntry.streetNumber }}
              </td>
            </tr>

            <tr>
              <th class="pb-50">
                <feather-icon
                  icon="MapPinIcon"
                  class="mr-75"
                />
                <span class="font-weight-bold">PLZ | Stadt</span>
              </th>
              <td class="pb-50 text-capitalize">
                {{ offerEntry.zipCode }} {{ offerEntry.city }}
              </td>
            </tr>

            <tr>
              <th class="pb-50">
                <feather-icon
                  icon="PhoneIcon"
                  class="mr-75"
                />
                <span class="font-weight-bold">Telefon</span>
              </th>
              <td>
                {{ offerEntry.phoneNumber }}
              </td>
            </tr>
            <tr>
              <th class="pb-50">
                <feather-icon
                  icon="SmartphoneIcon"
                  class="mr-75"
                />
                <span class="font-weight-bold">Mobile</span>
              </th>
              <td>
                {{ offerEntry.mobileNumber }}
              </td>
            </tr>
            <tr>
              <th class="pb-50">
                <feather-icon
                  icon="PrinterIcon"
                  class="mr-75"
                />
                <span class="font-weight-bold">Fax</span>
              </th>
              <td>
                {{ offerEntry.faxNumber }}
              </td>
            </tr>
            <tr>
              <th class="pb-50">
                <feather-icon
                  icon="FlagIcon"
                  class="mr-75"
                />
                <span class="font-weight-bold">Land</span>
              </th>
              <td>
                {{ offerEntry.country }}
              </td>
            </tr>
          </table>
        </b-card>
      </b-col>
      <b-col
        cols="12"
        xl="4"
        lg="5"
        md="12"
      >
        <b-card>
          <h5>
            Erstellt
          </h5>
          <b-card-text>
            {{ getFormattedDate(offerEntry.created_at) }}
          </b-card-text>
          <h5>
            Angebot aktiv?
          </h5>
          <b-form-checkbox
            v-model="offerEntry.isActive"
            checked="true"
            name="check-button"
            switch
            :disabled="isDisabled()"
            @change="isActiveSwitch"
          >
            Angebot aktiv?
          </b-form-checkbox>
          <!-- <b-badge v-if="offerEntry.isActive" variant="success" class="mb-1">
            Ja
          </b-badge>
          <b-badge v-else variant="danger" class="mb-1">
            Nein
          </b-badge> -->
          <h5 class="mt-75">
            Ursprung
          </h5>
          <b-button
            variant="primary"
            @click="$router.push('/inquiries-view/' + offerEntry.inquiry.id)"
          >
            Anfrage öffnen
          </b-button>

          <b-card-text>
            {{ offerEntry.leadSource }}
          </b-card-text>
        </b-card>
      </b-col>
      <!-- <b-col cols="12" xl="12" lg="12" md="12">
        <b-card title="Beschreibung">
          <b-card-text>
            {{ offerEntry.message }}
          </b-card-text>
        </b-card>
      </b-col> -->
    </b-row>

    <b-modal
      ref="modal-isActive"
      centered
      title="Angebot deaktivieren?"
      ok-title="Ok"
      cancel-title="Abbrechen"
      cancel-variant="outline-secondary"
      :no-close-on-backdrop="true"
      @ok="deactivateOfferSubmitted"
    >
      Angebot wirklich deaktivieren? Angebot kann nicht wieder aktiviert werden
      und alle reservierten Lagerplätze werden freigegeben.

      <b-form>
        <b-form-group label-for="vue-select">
          <!-- <v-select
            v-model="this.selectedOption"
            :clearable="false"
            :options="this.qualificationOptions"
            placeholder="Auswahl"
            @option:selected="onChange($event)"
          /> -->
        </b-form-group>
      </b-form>
    </b-modal>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BCardText,
  BButton,
  BFormCheckbox,
  BBadge,
  VBModal,
  BModal,
} from 'bootstrap-vue'
import helper from '@/helper/helper'

export default {
  setup() {
    const { getFormattedDate } = helper()

    return { getFormattedDate }
  },
  components: {
    BCard,
    BRow,
    BCol,
    BCardText,
    BButton,
    BFormCheckbox,
    BBadge,
    VBModal,
    BModal,
  },
  props: {
    offerEntry: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {}
  },
  methods: {
    isActiveSwitch() {
      // this.$emit('toggleactiveSwitch')
      this.$refs['modal-isActive'].show()
    },
    deactivateOfferSubmitted() {
      // Set storageReservation from storage to false
      this.offerEntry.storages.forEach(storage => {
        // eslint-disable-next-line no-param-reassign
        storage.storageReservation = false
        this.$store
          .dispatch('updateStorage', storage)
          .then(result => {
            console.log(result)
          })
          .catch(error => {
            console.log(error)
          })
      })
      this.offerEntry.isActive = false

      this.$store
        .dispatch('updateOffer', this.offerEntry)
        .then(result => {
          console.log(result)
        })
        .catch(error => {
          console.log(error)
        })
    },
    isDisabled() {
      if (this.offerEntry.isActive === false) {
        return true
      }
      return false
    },
  },
}
</script>
<style lang="scss">
hr.style1 {
  border-top: 1px solid #8c8b8b;
}
hr.style5 {
  background-color: #fff;
  border-top: 2px dashed #8c8b8b;
}
</style>
