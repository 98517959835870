<template>
  <div>
    <!-- User Info: Left col -->
    <!-- {{ this.offerEntry.id }} -->
    <b-card no-body>
      <b-table
        ref="refListTable"
        striped
        hover
        :current-page="currentPage"
        :per-page="perPage"
        :items="offerEntry.storages"
        :fields="fields"
        :filter="filter"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        responsive
        show-empty
        empty-text="Keine Einträge gefunden"
        @row-clicked="onRowClicked"
      >
        <template #cell(location)="data">
          {{ locationById(data.item.location).name }}
        </template>
        <template #cell(rentStart)="data">
          {{ getFormattedDate(data.item.rentStart) }}
        </template>
        <template #cell(rentEnd)="data">
          {{ getFormattedDate(data.item.rentEnd) }}
        </template>
        <template #cell(storageReservation)="data">
          <b-badge v-if="data.item.storageReservation" variant="danger">
            Ja
          </b-badge>
          <b-badge v-else variant="success"> Nein </b-badge>
        </template>
        <template #cell(releaseStorageReservation)="data">
          <b-button
            v-if="data.item.storageReservation"
            variant="success"
            @click="showReleaseStorageReservation(data.item)"
          >
            Freigeben?
          </b-button>
        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted"
              >Zeige {{ dataMeta.from }} bis {{ dataMeta.to }} von
              {{ dataMeta.of }} Einträge</span
            >
          </b-col>

          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
              first-number
              last-number
              prev-class="prev-item"
              next-class="next-item"
              class="mb-0"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
    <b-modal
      id="modal-release-storage"
      :v-model="storage"
      title="Lagerplatz freigeben"
      cancel-variant="outline-secondary"
      cancel-title="Abbrechen"
      ok-variant="danger"
      ok-title="Freigeben"
      modal-class="modal-danger"
      centered
      @ok="releaseStorageReservation"
    >
      <b-card-text class="my-2">
        Reservierung für den Lagerplatz {{ storage.storageIdent }} aufheben und
        freigeben?
      </b-card-text>
    </b-modal>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BCardText,
  BButton,
  BTable,
  BBadge,
  BPagination,
  BModal,
  VBModal,
  BForm,
  BFormInput,
  BFormGroup,
  BFormTextarea,
} from 'bootstrap-vue'
import helper from '@/helper/helper'
import tableHelper from '../../Table+Helper'

export default {
  setup() {
    const { getFormattedDate } = helper()
    const {
      perPageOptions,
      perPage,
      filter,
      totalRows,
      currentPage,
      refListTable,
      dataMeta,
      refetchData,
    } = tableHelper()

    return {
      perPageOptions,
      perPage,
      filter,
      totalRows,
      currentPage,
      refListTable,
      dataMeta,
      refetchData,
      getFormattedDate,
    }
  },
  components: {
    BCard,
    BRow,
    BCol,
    BCardText,
    BButton,
    BTable,
    BBadge,
    BPagination,
    BModal,
    VBModal,
    BForm,
    BFormInput,
    BFormGroup,
    BFormTextarea,
  },

  props: {
    offerEntry: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      sortBy: 'id',
      sortDesc: true,
      storage: {},
      fields: [
        {
          key: 'storageIdent',
          label: 'ID',
          sortable: true,
        },
        {
          key: 'name',
          label: 'Name',
          sortable: true,
        },
        {
          key: 'price',
          label: 'Preis',
          sortable: true,
        },
        {
          key: 'location',
          label: 'Ort',
          sortable: true,
        },
        {
          key: 'rentStart',
          label: 'Von',
        },
        {
          key: 'rentEnd',
          label: 'Bis',
        },
        {
          key: 'storageReservation',
          label: 'Reserviert',
        },
        {
          key: 'releaseStorageReservation',
          label: 'Freigeben',
        },
      ],
    }
  },

  methods: {
    showReleaseStorageReservation(item) {
      this.storage = JSON.parse(JSON.stringify(item))
      this.$bvModal.show('modal-release-storage')
    },

    releaseStorageReservation() {
      this.storage.storageReservation = false

      this.$store.dispatch('updateStorage', this.storage).then(() => {
        this.$store.dispatch('getOfferEntries').then(() => {
          const hasTrueReservation = this.offerEntry.storages.some(
            obj => obj.storageReservation === true
          )

          if (!hasTrueReservation) {
            console.log('no reservation')
            this.offerEntry.isActive = false

            this.$store
              .dispatch('updateOffer', this.offerEntry)
              .then(result => {
                console.log(result)
              })
              .catch(error => {
                console.log(error)
              })
          }
        })
        this.$store.dispatch('getStorageEntries')
      })

      // loop through offerEntries and check if there is a storageReservation and if not, set offerEntry.isActive to false
    },

    locationById(id) {
      return this.$store.getters.locationById(Number(id))
    },
    onRowClicked(item) {
      this.$router.push(`/storages-view/${item.id}`)
    },
  },
}
</script>
<style lang="scss">
hr.style1 {
  border-top: 1px solid #8c8b8b;
}
hr.style5 {
  background-color: #fff;
  border-top: 2px dashed #8c8b8b;
}
</style>
